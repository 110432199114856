<template>
    <b-card no-body class="mb-0" >
        <div class="m-2">
            <b-row>
                <b-col
                    cols="12"
                    md="12"
                    class="mb-1 mb-md-0"
                >
                <b-form @submit.prevent>
                    <b-row>

                    <!-- name -->
                    <b-col cols="6">
                        <b-form-group
                        label="Nom"
                        label-for="vi-title"
                        >
                        <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                            </b-input-group-prepend>
                            <b-form-input
                            id="vi-name"
                            v-model="datas.name"
                            placeholder="Name"
                            required
                            />
                        </b-input-group>
                        </b-form-group>
                    </b-col>

                    <!-- date -->
                    <b-col cols="6">
                        <b-form-group
                        label="Date"
                        label-for="vi-title"
                        >
                        <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                            </b-input-group-prepend>
                            <b-form-input
                            id="vi-date"
                            v-model="datas.date"
                            placeholder="Date"
                            type="date"
                            required
                            />
                        </b-input-group>
                        </b-form-group>
                    </b-col>

                    <!-- km -->
                    <b-col cols="6">
                        <b-form-group
                        label="KM"
                        label-for="vi-km"
                        >
                        <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                            </b-input-group-prepend>
                            <b-form-input
                            id="vi-km"
                            v-model="datas.km"
                            placeholder="KM"
                            type="number"
                            />
                        </b-input-group>
                        </b-form-group>
                    </b-col>

                    <!-- lieu -->
                    <b-col cols="6">
                        <b-form-group
                        label="Lieu"
                        label-for="vi-lieu"
                        >
                        <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                            </b-input-group-prepend>
                            <b-form-input
                            id="vi-lieu"
                            v-model="datas.lieu"
                            placeholder="Lieu"
                            />
                        </b-input-group>
                        </b-form-group>
                    </b-col>

                    <!-- prix ttc -->
                    <b-col cols="6">
                        <b-form-group
                        label="Prix TTC"
                        label-for="vi-prixttc"
                        >
                        <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text>
                            </b-input-group-prepend>
                            <b-form-input
                            id="vi-prixttc"
                            v-model="datas.prixttc"
                            placeholder="Prix TTC"
                            type="number"
                            required
                            />
                        </b-input-group>
                        </b-form-group>
                    </b-col>

                    <!-- categorie -->
                    <b-col cols="6">
                        <b-form-group
                        label="Catégorie"
                        label-for="vi-categorie"
                        >
                            <b-form-select
                            v-model="datas.category"
                            :options="options"
                            />
                        </b-form-group>
                    </b-col>

                    <!-- Camera Base64-->
                    <b-col
                        cols="12"
                        md="6"
                        class="align-items-center justify-content-start mb-1 mb-md-0">
                        <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="success"
                            class="btn-icon mb-2"
                            block
                            @click="takePicturebase64"
                            >
                            <feather-icon icon="CameraIcon" />
                            Insérer le ticket
                        </b-button>
                        <b-card-text class="mb-2">Ticket : </b-card-text>
                        <img v-bind:src="this.base64Image" width="50%" heigth="auto" />
                    </b-col>


                    <!-- reset and submit -->
                    <b-col cols="12" class="mt-2">
                        <b-button
                        @click="submitForm()"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1">
                        Valider
                        </b-button>
                        <b-button
                            @click="$router.go(-1)"
                            variant="dark"
                            class="mr-1">
                            Retour
                        </b-button>
                    </b-col>
                    </b-row>
                </b-form>
            </b-col>
        </b-row>
    </div>
</b-card>
</template>

<script>
import {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BLink,
    BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PouchDB from 'pouchdb'
import { Camera, CameraResultType } from '@capacitor/camera'
import moment from 'moment'

export default {
    components: {
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BInputGroup,
        BInputGroupPrepend,
        BForm,
        BButton,
        BLink,
        BFormSelect,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            content: '',
            datas: [],
            base64Image: '',
            selected: null,
            options: [
                { value: null, text: 'Veuillez sélectionner une option' },
                { value: 'Essence', text: 'Essence' },
                { value: 'Repas', text: 'Repas' },
                { value: 'Electricité', text: 'Electricité' },
                { value: 'Autres', text: 'Autres' },
            ],
        };
    },
    computed: {

    },
    methods: {
        takePicturebase64() {
            const image = Camera.getPhoto({
                quality: 90,
                allowEditing: false,
                width: 1024,
                preserveAspectRatio: true,
                resultType: CameraResultType.Base64
            })
            .then(res => {
                this.base64Image = 'data:image/jpeg;base64,'+res.base64String
                // console.log( this.base64Image)
            })
        },
        submitForm() {
            console.log( this.base64Image)
            this.localDB = new PouchDB('frais')
            this.remoteDB = new PouchDB('https://couchdb.placedusite.fr/frais')

            console.log(this.datas)
            
            this.localDB.post({
                type: 'frais',
                name: this.datas.name,
                date: this.datas.date,
                km: this.datas.km,
                lieu: this.datas.lieu,
                prixttc: this.datas.prixttc,
                category: this.datas.category,
                ticket: this.base64Image
            }).then((response) => {
                console.log('response', response)
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: `Information`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: `Notre de frais ajouté avec succès`,
                    },
                })  
            }).catch((err) => {
                console.log('err', err)
            })

            this.localDB.sync(this.remoteDB)
            this.$router.push({ name: 'listfrais' })
  
        }
    },
    created(){
       this.datas.name = 'Arnaud'
       this.datas.date = moment().format('YYYY-MM-DD')
    }
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
